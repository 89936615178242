/* eslint-disable i18next/no-literal-string */
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import languageDetectorI18next from "i18next-browser-languagedetector";
import AUTH_ES from "../auth/i18n/es.json";
import CONTRIBUTIONS_ES from "../contributions/i18n/es.json";
import VALIDATIONS_ES from "../validations/i18n/es.json";
import USERS_ES from "../users/i18n/es.json";
import COMMITTEES_ES from "../committees/i18n/es.json";
import UI_ES from "../ui/i18n/es.json";
import ICONS_ES from "../icons/i18n/es.json";
import AUTH_PT from "../auth/i18n/pt.json";
import CONTRIBUTIONS_PT from "../contributions/i18n/pt.json";
import VALIDATIONS_PT from "../validations/i18n/pt.json";
import USERS_PT from "../users/i18n/pt.json";
import COMMITTEES_PT from "../committees/i18n/pt.json";
import UI_PT from "../ui/i18n/pt.json";
import ICONS_PT from "../icons/i18n/pt.json";
import AUTH_EN from "../auth/i18n/en.json";
import CONTRIBUTIONS_EN from "../contributions/i18n/en.json";
import VALIDATIONS_EN from "../validations/i18n/en.json";
import USERS_EN from "../users/i18n/en.json";
import COMMITTEES_EN from "../committees/i18n/en.json";
import UI_EN from "../ui/i18n/en.json";
import ICONS_EN from "../icons/i18n/en.json";
import { setLocale } from "yup";

i18n
  .use(languageDetectorI18next)
  .use(initReactI18next)
  .init({
    ns: ["auth", "contributions", "validations", "users", "committees"],
    resources: {
      es: {
        auth: AUTH_ES,
        contributions: CONTRIBUTIONS_ES,
        validations: VALIDATIONS_ES,
        users: USERS_ES,
        committees: COMMITTEES_ES,
        ui: UI_ES,
        icons: ICONS_ES,
      },
      pt: {
        auth: AUTH_PT,
        contributions: CONTRIBUTIONS_PT,
        validations: VALIDATIONS_PT,
        users: USERS_PT,
        committees: COMMITTEES_PT,
        ui: UI_PT,
        icons: ICONS_PT,
      },
      en: {
        auth: AUTH_EN,
        contributions: CONTRIBUTIONS_EN,
        validations: VALIDATIONS_EN,
        users: USERS_EN,
        committees: COMMITTEES_EN,
        ui: UI_EN,
        icons: ICONS_EN,
      },
    },
    detection: {
      order: ["localStorage"],
      caches: ["localStorage"],
    },
    fallbackLng: "es",

    interpolation: {
      escapeValue: false,
    },
  });

export interface ValidationError {
  key: string;
  values: Record<string, unknown>;
}

function getError(key: string) {
  return (values: unknown) => ({ key, values } as ValidationError);
}

setLocale({
  mixed: {
    default: getError("invalid_field"),
    required: getError("required"),
    defined: getError("defined"),
    oneOf: getError("one_of"),
  },
  string: {
    email: getError("email"),
  },
});

export default i18n;
