import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

const LANGS = [
  {
    label: "English",
    id: "en",
  },
  {
    label: "Español",
    id: "es",
  },
  {
    label: "Português",
    id: "pt",
  },
];

const LangSwitch: FunctionComponent = () => {
  const { i18n } = useTranslation();

  return (
    <div className={"lang-switch"}>
      <select
        className={"select small"}
        value={i18n.language}
        onChange={(ev) => {
          if (window) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (window as any).OneTrust.changeLanguage(ev.target.value);
          }
          i18n.changeLanguage(ev.target.value);
        }}
      >
        {LANGS.map((lang) => (
          <option key={lang.id} value={lang.id}>
            {lang.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default LangSwitch;
