import { FunctionComponent } from "react";
import {
  PRIVATE_CONTACT_LINK,
  PRIVATE_TERMS_OF_USE_LINK,
} from "../../../routes/private";
import NavLink from "../../routing/components/NavLink";
import { useTranslation } from "react-i18next";

const PrivateFooter: FunctionComponent = () => {
  const { t } = useTranslation(["auth", "ui"]);

  return (
    <div className="page-footer">
      <a
        href="https://www.knaufinsulation.es/"
        target="_blank"
        rel="noreferrer"
        className="link discreet-link"
      >
        {t("auth:GROUP_WEBSITE")}
      </a>
      <NavLink to={PRIVATE_TERMS_OF_USE_LINK} className="link discreet-link">
        {t("auth:TERMS_OF_USE")}
      </NavLink>
      <a
        href="https://www.knaufinsulation.es/politica-de-privacidad-y-proteccion-de-datos-GDPR"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:SECURITY_POLICY")}
      </a>
      <a
        href="https://www.knaufinsulation.es/politica-de-cookies"
        rel="noreferrer"
        target="_blank"
        className="link discreet-link"
      >
        {t("auth:COOKIES_POLICY")}
      </a>
      <NavLink to={PRIVATE_CONTACT_LINK} className={"link discreet-link"}>
        {t("ui:CONTACT")}
      </NavLink>
      <div
        className="copyright"
        dangerouslySetInnerHTML={{ __html: t("auth:COPYRIGHT") }}
      />
    </div>
  );
};

export default PrivateFooter;
