import React, { FunctionComponent } from "react";
import { RouteComponentProps, useNavigate } from "@reach/router";
import { Form, Formik } from "formik";
import { number, object, SchemaOf, string } from "yup";
import {
  COMMITTEE_STATUS_MAP,
  COMMITTEE_TYPES,
  CommitteeToSend,
  getCommitteeStatus,
} from "../../../../services/committees/committee";
import ValidationsErrors from "../../../../services/validations/ValidationsErrors";
import { useTranslation } from "react-i18next";
import { useProvideCommittee } from "../../../../services/committees/useProvideCommittee";
import { useToasts } from "../../../../services/toast-notifications";
import Link from "../../../../services/routing/components/Link";
import {
  COMMITTEE_DETAILS_EVALUATIONS_LINK,
  COMMITTEES_PANEL_LINK,
} from "../../../../routes/committees";
import generatePath from "../../../../services/routing/generatePath";
import CommitteeFields from "../../../../services/committees/CommitteeFields";
import { PartialNullable } from "../../../../services/types/utility";

const CommitteeNew: FunctionComponent<RouteComponentProps> = () => {
  /* APIs */
  const { t } = useTranslation(["committees", "ui"]);
  const navigate = useNavigate();
  const { createCommittee } = useProvideCommittee();
  const { success, error } = useToasts();

  const CommitteeSchema: SchemaOf<CommitteeToSend> = object({
    // name: string().label("Nombre del comité").required(),
    name: string().label(t("committees:NAME")).required(),
    type: number()
      .label(t("committees:TYPE"))
      .nullable()
      .required()
      .oneOf(COMMITTEE_TYPES),
    date: string().label(t("committees:DATE")).nullable().required(),
  });

  return (
    <>
      <div className="page-head">
        <h1 className="page-title">{t("committees:COMMITTEE")}</h1>
        <Link className="link link-small" to={COMMITTEES_PANEL_LINK}>
          &lt; {t("ui:GO_BACK")}
        </Link>
      </div>
      <div className="page-content">
        <Formik
          validationSchema={CommitteeSchema}
          initialValues={
            {
              name: "",
              type: null,
              date: "",
            } as PartialNullable<CommitteeToSend> & {
              name: CommitteeToSend["name"];
            }
          }
          onSubmit={(values) => {
            return createCommittee(CommitteeSchema.validateSync(values)).then(
              (newCommittee) => {
                success(t("committees:createCommittee.SUCCESS"));
                return navigate(
                  generatePath(COMMITTEE_DETAILS_EVALUATIONS_LINK, {
                    committeeId: newCommittee.id,
                  }),
                );
              },
              () => error(t("committees:createCommittee.ERROR")),
            );
          }}
        >
          {({ values, errors }) => {
            return (
              <Form>
                <div className="grid">
                  <div className="col-md-1-3">
                    <CommitteeFields />
                  </div>
                  {!!values.date && (
                    <div className="col-md-1-3">
                      <div className="input-block">
                        <label className="input-label" htmlFor="status">
                          {t("committees:STATUS")}
                        </label>
                        <div className="input">
                          {t(
                            `committees:status.${
                              COMMITTEE_STATUS_MAP[
                                getCommitteeStatus({
                                  date: values.date,
                                })
                              ]
                            }`,
                          )}
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div>
                  <ValidationsErrors errors={errors} />
                </div>

                <div className="form-footer btns-bar">
                  <Link className="btn-2" to={COMMITTEES_PANEL_LINK}>
                    {t("ui:CANCEL")}
                  </Link>
                  <button className="btn-1" type="submit">
                    {t("ui:SAVE")}
                  </button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CommitteeNew;
